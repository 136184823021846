import mixpanel from 'mixpanel-browser';
import { User } from 'oidc-client-ts';

import api from 'api/api';
import { queryClient } from 'utilities/data/queryClient';

export const resetUserData = () => {
  delete api.defaults.headers.common.Authorization;
  mixpanel.reset();
  queryClient.resetQueries();
};

export const setupAuthAndMixpanel = (user: User) => {
  api.defaults.headers.common.Authorization = user ? `Bearer ${user.access_token}` : undefined;
  mixpanel.identify(user.profile.email as string);
  mixpanel.people.set({
    $email: user.profile.email,
    $first_name: user.profile.given_name,
    $last_name: user.profile.family_name,
    $picture: user.profile.picture,
  });
};
