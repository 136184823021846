import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProviderProps } from 'react-oidc-context';

import { appConfig } from 'utilities/env';

import { resetUserData } from './user';

const oidcConfig: AuthProviderProps = {
  authority: appConfig.kcAuthority,
  client_id: 'apollo-ui',
  redirect_uri: appConfig.kcRedirectUri,
  scope: 'openid email profile offline_access',
  automaticSilentRenew: true,
  loadUserInfo: true,
  monitorSession: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback: (user) => {
    window.history.replaceState({}, document.title, window.location.pathname);

    if (!user) return;

    // Redirect to the upgrade page if the user hasn't completed UMS registration
    // we should do this after mixpanel otherwise we'll never identify the user
    if (user.profile?.ums_registration != true) {
      window.location = `${appConfig.accountsUrl}/upgrade` as any;
    }
  },
  onRemoveUser: resetUserData,
};

export default oidcConfig;
