import { useCallback, useEffect, useState } from 'react';
import React from 'react';

import { hasAuthParams, useAuth } from 'react-oidc-context';

import { resetUserData, setupAuthAndMixpanel } from 'utilities/auth/user';

type Props = {
  children: React.ReactNode;
};

const ApiInterceptor = ({ children }: Props) => {
  const { isLoading, isAuthenticated, user, activeNavigator, signinSilent } = useAuth();

  // This is a guard to make sure we've set up mixpanel and the api headers before rendering the app for the first time
  const [isSetupDone, setIsSetupDone] = useState(false);

  const performAuthSetup = useCallback(async () => {
    if (isLoading || hasAuthParams() || !!activeNavigator) return;

    if (user) {
      // This case happens when the user isn't signed in on load, but we have a user from a previous session
      if (!isAuthenticated) {
        const refreshedUser = await signinSilent();
        refreshedUser && setupAuthAndMixpanel(refreshedUser);
      } else {
        setupAuthAndMixpanel(user);
      }
    } else {
      // Imperatively reset all tracking, token and query data if not signed in. This happnens in three cases:
      // 1. User isn't signed in on load
      // 2. User session expires while offline
      // 3. User's session expires in real time, or sign out from another tab
      // This is mostly a noop for case 1 and 2, as at this time there are no queries registered and no mixpanel data being tracked,
      // but we need it for case 3, as there's a state change while the app is "alive" we need to react to.
      resetUserData();
    }

    setIsSetupDone(true);
  }, [activeNavigator, isAuthenticated, isLoading, signinSilent, user]);

  useEffect(() => {
    performAuthSetup();
  }, [performAuthSetup]);

  return isSetupDone ? <>{children}</> : null;
};

export default ApiInterceptor;
