import { useState } from 'react';

import {
  Badge,
  Modal,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
  Stack,
  IconButton,
  ModalBody,
  ModalCloseButton,
  HStack,
  Icon,
  useColorModeValue,
} from '@ecoinvent/ui';
import mixpanel from 'mixpanel-browser';
import { FiMoreVertical } from 'react-icons/fi';
import { useParams } from 'react-router-dom';

import CopyToClipboardButton from 'components/shared/CopyToClipboardButton';
import { ElementaryExchange, IntermediateExchange } from 'hooks/data/datasets/exchanges/types';
import useVersionInfo from 'hooks/useVersionInfo';
import ExchangeModalContent from 'pages/Exchanges/components/ExchangeView/ExchangeItem/ExchangeModalContent';
import { capitalizeFirstLetter } from 'utilities/helpers/formats';

import { CombinedExchange, ExchangeFilter } from '../types';

import ActivityLink from './ActivityLink';
import ExchangeItemDetails from './ExchangeItemDetails';

type Props = {
  item: CombinedExchange;
  enabledFeatures: ExchangeFilter[];
  baseUrl?: string;
};

const renderExchangeGroup = (exchangeGroup: string) => {
  switch (exchangeGroup) {
    case 'Reference product':
      return <Badge colorScheme="red">Reference product</Badge>;
    case 'From technosphere':
      return <Badge colorScheme="blue">Technosphere</Badge>;
    case 'Waste to treatment':
      return <Badge colorScheme="orange">Waste</Badge>;
    case 'By-product (Allocatable)':
    case 'By-product (Recyclable)':
    case 'By-product (Waste)':
      return <Badge colorScheme="purple">{exchangeGroup}</Badge>;
    default:
      return <Badge colorScheme="gray">Elementary</Badge>;
  }
};

const ExchangeItem = ({ item, enabledFeatures, baseUrl = '' }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const modalBg = useColorModeValue('white', 'gray.800');
  const grayTextColor = useColorModeValue('gray.500', 'gray.400');
  const [isHovered, setIsHovered] = useState(false);

  const itemAsIntermediateExchange = item as IntermediateExchange;
  const itemAsElementaryExchange = item as ElementaryExchange;

  const { ver: version, system_model } = useVersionInfo();
  const { spold_id } = useParams();

  const shouldShowDetails = enabledFeatures.includes('comment') || enabledFeatures.includes('uncertainty');

  return (
    <Stack spacing={0} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <Stack py={2}>
        <HStack justify={'space-between'}>
          <Stack spacing={0.5}>
            <HStack>
              <Text textTransform={'capitalize'} fontWeight={'medium'}>
                {item.name}
              </Text>
              {renderExchangeGroup(item['group'])}
            </HStack>
            {itemAsIntermediateExchange?.link && baseUrl && (
              <ActivityLink baseUrl={baseUrl} link={itemAsIntermediateExchange?.link} />
            )}
            {itemAsElementaryExchange?.compartment && (
              <Text fontSize={'sm'} color={grayTextColor}>
                {capitalizeFirstLetter(`${itemAsElementaryExchange.compartment}, ${itemAsElementaryExchange.sub_compartment}`)}
              </Text>
            )}
          </Stack>
          <HStack>
            {item['amount'] && <Text color="text.secondary">{`${item.amount.toExponential(4)} ${item['unit']}`}</Text>}
            <CopyToClipboardButton
              value={item.amount}
              opacity={isHovered ? 1 : 0}
              onValueCopied={() => {
                mixpanel.track('Copy Dataset Value', {
                  type: 'exchange',
                  group: item.group,
                  name: item.name,
                  version,
                  system_model,
                  spold_id,
                });
              }}
            />
            <IconButton
              variant={'ghost'}
              colorScheme="gray"
              icon={<Icon as={FiMoreVertical} />}
              onClick={onOpen}
              aria-label="open detail"
            />
          </HStack>
        </HStack>
        {shouldShowDetails && <ExchangeItemDetails item={item} enabledFeatures={enabledFeatures} />}
      </Stack>
      <Modal onClose={onClose} isOpen={isOpen} size={'5xl'}>
        <ModalOverlay />
        <ModalContent bg={modalBg}>
          <ModalHeader fontFamily={'heading'} fontSize={'3xl'}>
            Exchange details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ExchangeModalContent item={item as IntermediateExchange & { key: string }} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

export default ExchangeItem;
