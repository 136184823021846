import React from 'react';

import { Table, TableContainer, Tbody, Td, Th, Thead, Tr, Stack, Badge, Text, useColorModeValue } from '@ecoinvent/ui';

import { ExchangeUncertainty, IntermediateExchange } from 'hooks/data/datasets/exchanges/types';
import { capitalizeFirstLetter, humanizeCamelCase } from 'utilities/helpers/formats';

import { pedigreeMatrixLabels } from '../ExchangeModalContent/DataQuality';

type Props = { uncertainty: IntermediateExchange['uncertainty'] };

const ExchangeUncertaintyView = ({ uncertainty }: Props) => {
  const uncertaintyKeys = Object.keys(uncertainty?.value ?? {}) as Array<keyof ExchangeUncertainty['value']>;
  const parsedMatrix: number[] | null = uncertainty?.pedigreeMatrix ? JSON.parse(uncertainty?.pedigreeMatrix) : null;

  const headerBg = useColorModeValue('gray.100', 'gray.700');
  const badgeColor = useColorModeValue('gray.800', 'gray.200');

  return (
    <Stack>
      {uncertainty?.comment && (
        <Stack>
          <Text fontWeight={'medium'}>Comment</Text>
          <Text fontSize={'sm'}>{capitalizeFirstLetter(uncertainty?.comment)}</Text>
        </Stack>
      )}
      {parsedMatrix && (
        <Stack>
          <Text fontWeight={'medium'}>Data quality</Text>
          <Stack direction={'row'} spacing={2}>
            {parsedMatrix.map((value, index) => (
              <Badge
                size={'small'}
                px={2}
                variant={'outline'}
                color={badgeColor}
                borderRadius={'full'}
                key={pedigreeMatrixLabels[index]}
              >
                {pedigreeMatrixLabels[index]}: {value}
              </Badge>
            ))}
          </Stack>
        </Stack>
      )}
      <Stack>
        <Text fontWeight={'medium'}>Values</Text>
        <TableContainer borderRadius={'md'} borderWidth={'1px'}>
          <Table variant={'simple'} size={'sm'}>
            <Thead bg={headerBg}>
              <Th>Type</Th>
              {uncertaintyKeys.map((key_string, idx) => {
                return <Th key={idx}>{humanizeCamelCase(key_string)}</Th>;
              })}
            </Thead>
            <Tbody>
              <Tr>
                <Td borderBottom={'0px'}>{uncertainty?.type}</Td>
                {uncertaintyKeys.map((key_string, idx) => {
                  return (
                    <Td borderBottom={'0px'} key={idx}>
                      {uncertainty?.value[key_string].toExponential(4)}
                    </Td>
                  );
                })}
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>
      </Stack>
    </Stack>
  );
};

export default ExchangeUncertaintyView;
